var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : _vm.action === 'new' ? 900 : 600,"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [(_vm.mini)?_c('v-btn',_vm._g({attrs:{"elevation":"0","small":"","icon":"","disabled":_vm.appointment.available_dates.length === 0 ||
              _vm.appointment.status === 'creating' ||
              _vm.disabled}},Object.assign({}, on, onTooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-plus")])],1):(_vm.text)?_c('v-btn',_vm._g({attrs:{"outlined":"","height":"30"}},on),[_vm._v(" "+_vm._s(_vm.$t("add", { name: _vm.$tc("customer") }))+" ")]):_c('v-btn',_vm._g({attrs:{"icon":"","small":"","outlined":"","elevation":"0","block":_vm.block,"disabled":_vm.appointment.available_dates.length === 0 ||
              _vm.appointment.status === 'creating' ||
              _vm.disabled}},Object.assign({}, on, onTooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("add", { name: _vm.$tc("customer") }))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:" py-3 justify-center text-decoration-none text-center\n                      d-block\n                      text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("add", { name: _vm.$tc("customer") }))+" ")]),_c('v-card-subtitle',{staticClass:"mt-0 text-center primary--text"},[_vm._v(" "+_vm._s(_vm.$t("add_extra_consent"))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false;
          _vm.action = null;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('form',{attrs:{"action":"","data-vv-scope":"extra-customer-form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm('extra-customer-form')}}},[(_vm.action === 'new')?_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","dense":"","id":"URL","hide-details":"","readonly":""},model:{value:(_vm.URL),callback:function ($$v) {_vm.URL=$$v},expression:"URL"}},[_c('template',{slot:"prepend-inner"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.copy()}}},on),[_vm._v(" mdi-content-copy ")])]}}],null,false,2496974216)},[_vm._v(" "+_vm._s(_vm.$t("copy"))+" ")])],1)],2)],1):(_vm.action === 'existing')?_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"8"}},[_c('label',{domProps:{"textContent":_vm._s(_vm.$tc('customer'))}}),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","hide-details":"","dense":"","items":_vm.customers,"item-value":"id","search-input":_vm.searchCustomer,"loading":_vm.isLoadingCustomer,"cache-items":"","item-text":"user.fullname","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('extra-customer-form.customer'),"data-vv-name":"customer"},on:{"update:searchInput":function($event){_vm.searchCustomer=$event},"update:search-input":function($event){_vm.searchCustomer=$event}},model:{value:(_vm.customer_id),callback:function ($$v) {_vm.customer_id=$$v},expression:"customer_id"}})],1)],1)],1):_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8","md":"6"}},[_c('base-border-card',{attrs:{"title":_vm.$t('customers.new'),"two_lines":true},on:{"card_clicked":function($event){return _vm.select('new')}}})],1),_c('v-col',{attrs:{"cols":"8","md":"6"}},[_c('base-border-card',{attrs:{"title":_vm.$t('existing', { name: _vm.$tc('customer') }),"two_lines":true},on:{"card_clicked":function($event){return _vm.select('existing')}}})],1)],1)],1),(_vm.action)?_c('v-card-actions',[_c('v-spacer'),(_vm.action === 'existing')?[_c('v-btn',{staticClass:"mr-3",staticStyle:{"height":"25px"},attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){_vm.dialog = false;
              _vm.action = null;}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{staticStyle:{"color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"elevation":"0","type":"submit"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"14px"}},[_vm._v("$save")]),_vm._v(" "+_vm._s(_vm.$t("save", { name: "" }))+" ")],1)]:(_vm.action)?[_c('v-btn',{staticStyle:{"color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"elevation":"0","color":"primary","href":_vm.URL}},[_vm._v(" "+_vm._s(_vm.$t("to_complete"))+" ")])]:_vm._e(),_c('v-btn',{staticStyle:{"color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"elevation":"0","type":"submit"},on:{"click":function($event){return _vm.openSend()}}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"14px"}},[_vm._v("$send")]),_vm._v(" "+_vm._s(_vm.$t("send", { name: "" }))+" ")],1),_c('SendMail',{ref:"sendMail",attrs:{"hidden":"","appointment":_vm.appointment}})],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }