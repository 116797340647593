<template>
  <v-dialog
    :width="
      $vuetify.breakpoint.smAndDown ? '100%' : action === 'new' ? 900 : 600
    "
    v-model="dialog"
    persistent
  >
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            v-if="mini"
            v-on="{ ...on, ...onTooltip }"
            elevation="0"
            small
            icon
            :disabled="
              appointment.available_dates.length === 0 ||
                appointment.status === 'creating' ||
                disabled
            "
          >
            <v-icon small>mdi-account-plus</v-icon>
          </v-btn>
          <v-btn outlined height="30" v-else-if="text" v-on="on">
            {{ $t("add", { name: $tc("customer") }) }}
          </v-btn>
          <v-btn
            v-else
            v-on="{ ...on, ...onTooltip }"
            icon
            small
            outlined
            elevation="0"
            :block="block"
            :disabled="
              appointment.available_dates.length === 0 ||
                appointment.status === 'creating' ||
                disabled
            "
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </template>
        {{ $t("add", { name: $tc("customer") }) }}
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title
        class=" py-3 justify-center text-decoration-none text-center
                        d-block
                        text-uppercase"
      >
        {{ $t("add", { name: $tc("customer") }) }}
      </v-card-title>
      <v-card-subtitle class="mt-0 text-center primary--text">
        {{ $t("add_extra_consent") }}
      </v-card-subtitle>
      <div class="close">
        <v-btn
          icon
          @click="
            dialog = false;
            action = null;
          "
          small
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="extra-customer-form"
        @submit.prevent="validateForm('extra-customer-form')"
      >
        <v-card-text v-if="action === 'new'">
          <v-text-field
            outlined
            dense
            id="URL"
            hide-details
            readonly
            v-model="URL"
          >
            <template slot="prepend-inner">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon class="mr-2" v-on="on" @click="copy()">
                    mdi-content-copy
                  </v-icon>
                </template>
                {{ $t("copy") }}
              </v-tooltip>
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-text v-else-if="action === 'existing'">
          <v-row justify="center">
            <v-col class="py-0" cols="8">
              <label v-text="$tc('customer')"></label>
              <v-autocomplete
                outlined
                hide-details
                dense
                v-model="customer_id"
                :items="customers"
                item-value="id"
                :search-input.sync="searchCustomer"
                :loading="isLoadingCustomer"
                cache-items
                item-text="user.fullname"
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('extra-customer-form.customer')"
                data-vv-name="customer"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row justify="center">
            <v-col cols="8" md="6">
              <base-border-card
                :title="$t('customers.new')"
                @card_clicked="select('new')"
                :two_lines="true"
              >
              </base-border-card>
            </v-col>
            <v-col cols="8" md="6">
              <base-border-card
                :title="$t('existing', { name: $tc('customer') })"
                @card_clicked="select('existing')"
                :two_lines="true"
              >
              </base-border-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="action">
          <v-spacer></v-spacer>
          <template v-if="action === 'existing'">
            <v-btn
              outlined
              @click="
                dialog = false;
                action = null;
              "
              style="height: 25px"
              elevation="0"
              class="mr-3"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              style="color: #363533; height: 25px; padding-top: 10px !important"
              elevation="0"
              type="submit"
            >
              <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
              {{ $t("save", { name: "" }) }}
            </v-btn>
          </template>
          <template v-else-if="action">
            <v-btn
              style="color: #363533; height: 25px; padding-top: 10px !important"
              elevation="0"
              color="primary"
              :href="URL"
            >
              {{ $t("to_complete") }}
            </v-btn>
          </template>
          <v-btn
            style="color: #363533; height: 25px; padding-top: 10px !important"
            elevation="0"
            type="submit"
            @click="openSend()"
            ><v-icon style="margin-right: 5px" size="14px">$send</v-icon>
            {{ $t("send", { name: "" }) }}
          </v-btn>
          <SendMail ref="sendMail" hidden :appointment="appointment" />
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import constants from "@/constants";
export default {
  name: "AddExtraCustomer",
  props: ["appointment", "mini", "disabled"],
  components: {
    CustomersForm: () => import("@/components/customer/Form"),
  },

  watch: {
    searchCustomer(val) {
      //console.log(val);
      this.fetchCustomers();
    },
  },
  data() {
    return {
      isLoadingCustomer: false,
      searchCustomer: "",
      dialog: false,
      action: null,
      customers: [],
      customer_id: null,
      customer: { user: { role_id: constants.roles.CUSTOMER } },
    };
  },
  computed: {
    URL() {
      let date = this.appointment.available_dates.find((d) => {
        d = new Date(d.date.substring(0, 10));
        let now = new Date();
        return d.getTime() > now.getTime();
      });

      console.log("date", date);

      if (!date && this.appointment.available_dates.length)
        date = this.appointment.available_dates[0];

      let url = `${window.location.origin}/?#/inscription/appointment/${this.appointment.id}/consent/${this.customer_id}/${date.id}`;

      if (this.action === "new")
        url = `${window.location.origin}/?#/inscription/appointment/${this.appointment.id}/consent/${date.id}`;
      return url;
    },
  },
  methods: {
    select(type) {
      this.action = type;
    },
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("URL");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.dialog = false;
      this.action = null;
      this.customer_id = null;
      this.$alert(this.$t("link_copy_success"), "", "");
    },
    ...mapActions("appointments", ["addExistingCustomer", "addNewCustomer"]),
    async validateForm(scope) {
      let result = await this.$validator.validateAll(scope);
      if (result) {
        this.addExistingCustomer({
          id: this.appointment.id,
          data: { customer_id: this.customer_id },
        }).then(() => {
          this.action = null;
          this.customer_id = null;
          this.dialog = false;
          this.$emit("update");
        });
      }
    },

    openSend() {
      this.$refs.sendMail.open("PAGA Y SEÑAL");
      console.log(this.appointmentTokenPayment.id);
      this.$refs.sendMail.signal_selected = this.appointmentTokenPayment.id;
    },
    ...mapActions("customers", ["getCustomers"]),
    fetchCustomers() {
      console.log("load", this.isLoadingCustomer);
      //if (this.isLoadingCustomer) return;
      this.isLoadingCustomer = true;
      let filters = {
        itemsPerPage: 20,
        search: this.searchCustomer,
      };
      if (this.$store.getters["auth/isStudio"])
        filters.studio_id = this.$store.state.auth.user.studio.id;
      if (this.$store.getters["auth/isTattooer"])
        filters.tattooer_id = this.$store.state.auth.user.tattooer.id;
      this.getCustomers({
        filters,
        pagination: {},
        loading: false,
      }).then((response) => {
        this.customers = response.data;
        this.isLoadingCustomer = false;
      });
    },
  },
};
</script>
<style scoped lang="sass">
label
  //line-height: 1
  height: 25px
  display: block
</style>
